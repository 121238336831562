<template>
  <div class="navbar">
    <breadcrumb style="margin-left: 20px" id="breadcrumb-container" class="breadcrumb-container"/>
    <div class="right-menu">
      <template>
        <screenfull id="screenfull" class="right-menu-item hover-effect"/>
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" >
        <div class="avatar-wrapper">
          <span>{{user.realName}}</span>
          <i class="el-icon-caret-bottom" style="margin-top: -5px"/>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
export default {
  components: {
    Breadcrumb,
  },
  name: "EcareHeader",
  data(){
    return{
      title:"财务管理系统"
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  methods: {
    logout() {
        if (window.location.hostname === 'localhost'){
            this.$store.dispatch('user/logout')
            this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        }else {
            this.$store.dispatch('user/logout')
            // 打开新标签页跳转到外部网址
            window.open('http://home.wlmqgtzs.com/login', '_blank');
            // 清空当前页面的历史记录
            window.history.replaceState({}, document.title, '/');
            // 关闭当前页面
            window.close();
        }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    //line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 15px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 40px;

      .avatar-wrapper {
        //margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          font-size: 33px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
