import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcSalary(data){
        return request({
            url: getUrl("salary/addUcSalary"),
            method: "post",
            data,
        })
    },
    deleteUcSalary(data){
        return request({
            url: getUrl("salary/deleteUcSalary"),
            method: "post",
            data,
        })
    },
    getUcSalary(data){
        return request({
            url: getUrl("salary/getUcSalary"),
            method: "post",
            data,
        })
    },
    getUcSalaryList(data){
        return request({
            url: getUrl("salary/getUcSalaryList"),
            method: "post",
            data,
        })
    },
    getUcSalaryPage(data){
        return request({
            url: getUrl("salary/getUcSalaryPage"),
            method: "post",
            data,
        })
    },
}
