import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcBill(data){
        return request({
            url: getUrl("bill/addUcBill"),
            method: "post",
            data,
        })
    },
    deleteUcBill(data){
        return request({
            url: getUrl("bill/deleteUcBill"),
            method: "post",
            data,
        })
    },
    updateUcBill(data){
        return request({
            url: getUrl("bill/updateUcBill"),
            method: "post",
            data,
        })
    },
    getUcBill(data){
        return request({
            url: getUrl("bill/getUcBill"),
            method: "post",
            data,
        })
    },
    getUcBillPage(data){
        return request({
            url: getUrl("bill/getUcBillPage"),
            method: "post",
            data,
        })
    },
    mergeBill(data){
        return request({
            url: getUrl("bill/mergeBill"),
            method: "post",
            data,
        })
    },
    pay(data){
        return request({
            url: getUrl("bill/pay"),
            method: "post",
            data,
        })
    },
    refund(data){
        return request({
            url: getUrl("bill/refund"),
            method: "post",
            data,
        })
    },
}
