import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    getUcBillFlow(data){
        return request({
            url: getUrl("billFlow/getUcBillFlow"),
            method: "post",
            data,
        })
    },
    getUcBillFlowPage(data){
        return request({
            url: getUrl("billFlow/getUcBillFlowPage"),
            method: "post",
            data,
        })
    },
    confirm(data){
        return request({
            url: getUrl("billFlow/confirm"),
            method: "post",
            data,
        })
    },
    exportBillFlow(data){
        return request({
            url: getUrl("billFlow/exportBillFlow"),
            method: "post",
            responseType: "blob",
            data,
        })
    },
}
