import dayjs from 'dayjs'

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function alertType(value) {
    console.log(value)
    let type = "";
    switch (value){
        case 1:
            type = '超出';
            break;
        case 2:
            type = '上线';
            break;
        case 3:
            type = '离线';
            break;
        case 4:
            type = '其它';
            break;
        default:
            break;
    }
    return type;
}

export function eqType(value) {
    console.log(value)
    let type = "";
    switch (value){
        case "AMMETER":
            type = '电表';
            break;
        case "WATER_METER":
            type = '水表';
            break;
        case "MONITOR":
            type = '监控';
            break;
        case "PDA":
            type = 'PDA';
            break;
        case "PATROL_LABEL":
            type = '标签';
            break;
        case "ENTRANCE_GUARD":
            type = '门禁';
            break;
        default:
            break;
    }
    return type;
}

export function orderTradeState(value) {
    let type = "";
    switch (value){
        case 'NOPAY':
            type = '未支付';
            break;
        case 'PROCESSING':
            type = '支付中';
            break;
        case 'SUCCESS':
            type = '支付成功';
            break;
        case 'FAIL':
            type = '支付失败';
            break;
        case 'PART-NOPAY':
            type = '部分收款-未支付';
            break;
        case 'PART-PROCESSING':
            type = '部分收款-支付中';
            break;
        case 'PART-SUCCESS':
            type = '部分收款-支付成功';
            break;
        case 'PART-FAIL':
            type = '部分收款-支付失败';
            break;
        case 'REFUND':
            type = '已退款';
            break;
        case 'PART-REFUND':
            type = '部分收款-已退款';
            break;
        default:
            break;
    }
    return type;
}

export function payStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未支付';
            break;
        case 1:
            state = '已支付';
            break;
        case 2:
            state = '待生成合同';
            break;
        case 3:
            state = '已生成合同';
            break;
        case 4:
            state = '已退费';
            break;
        case 6:
            state = '已取消';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}

export function orderType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '共享空间订单';
            break;
        case 1:
            state = '空间商品订单';
            break;
        case 2:
            state = '企业服务订单';
            break;
    }
    return state;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('alertType', alertType)
        Vue.filter('eqType', eqType)
        Vue.filter('orderTradeState', orderTradeState)
        Vue.filter('payStatus', payStatus)
        Vue.filter('orderType', orderType)
    }
}
