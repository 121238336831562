import Vue from "vue";
import VueRouter from "vue-router";
import ecareLay from "@/ecareLay/index.vue";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const commonRouters = [
    {
        path: "/login",
        hidden: true,
        meta: { title: "登录", icon: "el-icon-ed-shouye", affix: true },
        component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue"),
    },
    {
        path: "/",
        component: ecareLay,
        redirect: "/index",
        children: [
            {
                path: "/index",
                meta: { title: "首页", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/index.vue"),
            }
        ],
    },
    {
        path: "/emr",
        component: ecareLay,
        redirect: "/emr/list",
        children: [
            {
                path: "list",
                meta: { title: "电表收费规则", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/emr/list.vue"),
            },
            {
                path: "amr",
                meta: { title: "电表用量计费", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/emr/amr.vue"),
            }
        ],
    },
    {
        path: "/billing",
        component: ecareLay,
        redirect: "/billing/list",
        children: [
            {
                path: "list",
                meta: { title: "电表用量计费", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/billing/list.vue"),
            }
        ],
    },
    {
        path: "/salary",
        component: ecareLay,
        redirect: "/salary/list",
        children: [
            {
                path: "list",
                meta: { title: "账单模板", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/salary/list.vue"),
            }
        ],
    },
    {
        path: "/bill",
        component: ecareLay,
        redirect: "/bill/list",
        children: [
            {
                path: "list",
                meta: { title: "账单列表", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/bill/list.vue"),
            }
        ],
    },
    {
        path: "/billFlow",
        component: ecareLay,
        redirect: "/billFlow/list",
        children: [
            {
                path: "list",
                meta: { title: "账单流水", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/billFlow/list.vue"),
            },
            {
                path: "info",
                meta: { title: "账单流水详情", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/billFlow/info.vue"),
            }
        ],
    },
    {
        path: "/expense",
        component: ecareLay,
        redirect: "/expense/list",
        children: [
            {
                path: "list",
                meta: { title: "支出模版", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/expense/list.vue"),
            }
        ],
    },
    {
        path: "/expenditureFlow",
        component: ecareLay,
        redirect: "/expenditureFlow/list",
        children: [
            {
                path: "list",
                meta: { title: "支出列表", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/expenditureFlow/list.vue"),
            }
        ],
    },
    {
        path: "/processApplication",
        component: ecareLay,
        redirect: "/processApplication/list",
        children: [
            {
                path: "list",
                meta: { title: "流程管理", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/processApplication/list.vue"),
            }
        ],
    },
    // {
    //     path: "/amr",
    //     component: ecareLay,
    //     redirect: "/emr/amr",
    //     children: [
    //         {
    //             path: "list",
    //             meta: { title: "电表用量计费", icon: "el-icon-ed-shouye", affix: true },
    //             component: () => import(/* webpackChunkName: "login" */ "../pages/emr/amr.vue"),
    //         }
    //     ],
    // },
    {
        path: "/401",
        hidden: true,
        meta: {title: "401", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/common/401/index.vue"),
    },
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes: commonRouters,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;