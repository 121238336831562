import request from "@/utils/request";
function getUrl(url) {
    return "/user-auth/" + url;
}

export default {
    getMenuTree(data){
        return request({
            url: getUrl("menu/getMenuTree"),
            method: "post",
            data,
        })
    },
    getUserList(data){
        return request({
            url: getUrl("user/getUserList"),
            method: "post",
            data,
        })
    }
}