import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    getTopData(data){
        return request({
            url: getUrl("home/getTopData"),
            method: "post",
            data,
        })
    },
    getLeftData(data){
        return request({
            url: getUrl("home/getLeftData"),
            method: "post",
            data,
        })
    },
    getRightData(data){
        return request({
            url: getUrl("home/getRightData"),
            method: "post",
            data,
        })
    },

}
