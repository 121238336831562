import router from "./router";
import NProgress from 'nprogress' // progress bar
import store from './store'
import 'nprogress/nprogress.css' // progress bar style
import {getToken, setToken, removeToken} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import {getOption} from "./api/common";
import login from "./api/auth"

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login','/404'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    NProgress.start()
    document.title = getPageTitle(to.meta.title)
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login'||to.path === '/admin') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
        } else {
            // determine whether the user has obtained his permission roles through getInfo
            const hasRoles = store.getters.user.userCustom
            console.log(hasRoles)
            if (hasRoles) {
                next()
            } else {
                try {
                    let userCustom = ''
                    await store.dispatch('user/getInfo').then(res => {
                        userCustom = res.result.userCustom
                    })
                    const accessRoutes = await store.dispatch('permission/generateRoutes')
                    router.addRoutes(accessRoutes)
                    if (userCustom.includes('finance')) {
                        next({ ...to, replace: true })
                    } else {
                        next('/401')
                        // removeToken()
                    }
                } catch (error) {
                    console.log(error)
                    // remove token and go to login page to re-login
                    //await store.dispatch('user/resetToken')
                    // Message.error(error || 'Has Error')
                    if (window.location.hostname === 'localhost'){
                        next(`/login?redirect=${to.fullPath}`)
                        NProgress.done()
                    }else {
                        this.$store.dispatch('user/logout')
                        // 打开新标签页跳转到外部网址
                        window.open('http://home.wlmqgtzs.com/login', '_blank');
                        // 清空当前页面的历史记录
                        window.history.replaceState({}, document.title, '/');
                        // 关闭当前页面
                        window.close();
                    }
                }
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            if (window.location.hostname === 'localhost'){
                next(`/login?redirect=${to.fullPath}`)
                NProgress.done()
            }else {
                this.$store.dispatch('user/logout')
                // 打开新标签页跳转到外部网址
                window.open('http://home.wlmqgtzs.com/login', '_blank');
                // 清空当前页面的历史记录
                window.history.replaceState({}, document.title, '/');
                // 关闭当前页面
                window.close();
            }
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
