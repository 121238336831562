import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    getUcAmmeterPage(data){
        return request({
            url: getUrl("iot/getUcAmmeterPage"),
            method: "post",
            data,
        })
    },
    addUcElectricityRule(data){
        return request({
            url: getUrl("electricityRule/addUcElectricityRule"),
            method: "post",
            data,
        })
    },
}
