import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcExpenditureFlow(data){
        return request({
            url: getUrl("expenditureFlow/addUcExpenditureFlow"),
            method: "post",
            data,
        })
    },
    deleteUcExpenditureFlow(data){
        return request({
            url: getUrl("expenditureFlow/deleteUcExpenditureFlow"),
            method: "post",
            data,
        })
    },
    getUcExpenditureFlow(data){
        return request({
            url: getUrl("expenditureFlow/getUcExpenditureFlow"),
            method: "post",
            data,
        })
    },
    getUcExpenditureFlowPage(data){
        return request({
            url: getUrl("expenditureFlow/getUcExpenditureFlowPage"),
            method: "post",
            data,
        })
    },
}
