<!--侧边栏菜单展示-->
<template>
  <div class="sidebar-container" style="padding: 0 0 80px;width: 170px">
    <div class="sidebar-avatar">
      <el-avatar size="large" fit="fill" src="https://railway-park.oss-cn-shanghai.aliyuncs.com/website/img/2023/05/1684835833837.png"></el-avatar>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
          text-color="#fff"
          router
          :default-active="activeIndex"
          active-text-color="#fff"
          :router="true"
          background-color="#202033">
        <el-menu-item index="/index">
          <img src="https://railway-park.oss-cn-shanghai.aliyuncs.com/news/cover/2023/07/1690740540307.svg" style="width: 15px;height: 15px"/>
          <span style="margin-left: 8px;">首页</span>
        </el-menu-item>
        <el-submenu v-for="(item, index) in menus" :index="item.path" :key="index">
          <template slot="title">
            <img :src="item.icon" style="width: 15px;height: 15px"/>
            <span style="margin-left: 8px;">{{item.name}}</span>
          </template>
          <el-menu-item v-for="(item, index) in item.children" :key="index" :index="item.path">
            <img :src="item.icon" style="width: 15px;height: 15px"/>
            <span style="margin-left: 8px">{{item.name}}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activeIndex: 'index',
      query: {
        tenantId: '01H1S2X23V9Y7SRY0ZWH4668R3',
        userCustom: 'finance'
      },
      menus: []
    }
  },
  mounted() {
    this.activeIndex = this.$route.path
  },
  created() {
    this.getMenuTree()
  },
  methods: {
    getMenuTree() {
      this.$api.userAuth.getMenuTree(this.query).then(res=>{
        this.menus = res.result
      }).catch(error=>{
        this.$notify.error({
          title: '错误',
          message: error.msg
        });
      })
    }
  }
}
</script>

<style scoped>
.sidebar-container{
  min-height: 100vh;
  width: 124px;
  background-color:#202033
}
.sidebar-avatar{
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}
.scrollbar-wrapper {
  overflow-x: hidden !important;
}
</style>