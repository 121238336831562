import axios from 'axios'
import router from '../router'
import {Message } from 'element-ui'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: "https://gateway.wlmqgtzs.com", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()){
        config.headers['UchungToken'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    console.log(response)
    if(response.headers['UchungToken']!=undefined){
        setToken(response.headers['UchungToken'])
    }
    const res = response.data
      if (res.type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
          let fileName = response.headers["content-disposition"].substr(20)
          console.log(fileName)
          //浏览器兼容，Google和火狐支持a标签的download，IE不支持
          let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
          if (window.navigator && window.navigator.msSaveBlob) {
              //IE浏览器、微软浏览器
              /* 经过测试，微软浏览器Microsoft Edge下载文件时必须要重命名文件才可以打开，
                IE可不重命名，以防万一，所以都写上比较好 */
              window.navigator.msSaveBlob(blob, fileName);
          } else {
              //其他浏览器
              let link = document.createElement('a'); // 创建a标签
              link.style.display = 'none';
              let objectUrl = URL.createObjectURL(blob);
              link.href = objectUrl;
              link.download = decodeURIComponent(fileName)
              link.click();
              URL.revokeObjectURL(objectUrl);
          }
          return Promise.resolve()
      }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      if (res.code==500){
          removeToken();
          router.push(`/login?redirect=`+router.currentRoute.fullPath)
      }else {
          return Promise.reject(res)
      }
    } else {
      return res
    }
  },
  error => {
    console.log(error.response)
    if (error.response.status==401){
        return Promise.reject({code:401,data:null,msg:"暂无权限"})
    }else {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
  }
)

export default service