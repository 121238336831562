import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcChargeRule(data){
        return request({
            url: getUrl("chargeRule/addUcChargeRule"),
            method: "post",
            data,
        })
    },
    updateUcChargeRule(data){
        return request({
            url: getUrl("chargeRule/updateUcChargeRule"),
            method: "post",
            data,
        })
    },
    deleteUcChargeRule(data){
        return request({
            url: getUrl("chargeRule/deleteUcChargeRule"),
            method: "post",
            data,
        })
    },
    getUcChargeRule(data){
        return request({
            url: getUrl("chargeRule/getUcChargeRule"),
            method: "post",
            data,
        })
    },
    getUcChargeRulePage(data){
        return request({
            url: getUrl("chargeRule/getUcChargeRulePage"),
            method: "post",
            data,
        })
    },
    getUcChargeRuleList(data){
        return request({
            url: getUrl("chargeRule/getUcChargeRuleList"),
            method: "post",
            data,
        })
    },
}
