import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcExpenseTemplate(data){
        return request({
            url: getUrl("expenseTemplate/addUcExpenseTemplate"),
            method: "post",
            data,
        })
    },
    deleteUcExpenseTemplate(data){
        return request({
            url: getUrl("expenseTemplate/deleteUcExpenseTemplate"),
            method: "post",
            data,
        })
    },
    getUcExpenseTemplate(data){
        return request({
            url: getUrl("expenseTemplate/getUcExpenseTemplate"),
            method: "post",
            data,
        })
    },
    getUcExpenseTemplateList(data){
        return request({
            url: getUrl("expenseTemplate/getUcExpenseTemplateList"),
            method: "post",
            data,
        })
    },
    getUcExpenseTemplatePage(data){
        return request({
            url: getUrl("expenseTemplate/getUcExpenseTemplatePage"),
            method: "post",
            data,
        })
    },
}
