import request from "@/utils/request";
function getUrl(url) {
   return "/finance/" + url;
}

export default {
    addUcProcessApplication(data){
        return request({
            url: getUrl("processApplication/addUcProcessApplication"),
            method: "post",
            data,
        })
    },
    deleteUcProcessApplication(data){
        return request({
            url: getUrl("processApplication/deleteUcProcessApplication"),
            method: "post",
            data,
        })
    },
    getUcProcessApplication(data){
        return request({
            url: getUrl("processApplication/getUcProcessApplication"),
            method: "post",
            data,
        })
    },
    getUcProcessApplicationPage(data){
        return request({
            url: getUrl("processApplication/getUcProcessApplicationPage"),
            method: "post",
            data,
        })
    },
    approve(data){
        return request({
            url: getUrl("processApplication/approve"),
            method: "post",
            data,
        })
    },
}
