import request from "@/utils/request";
function getUrl(url) {
    return "/finance/" + url;
}

export default {
    getOrderItemInfo(data){
        return request({
            url: getUrl("business/orderItem/info"),
            method: "post",
            data,
        })
    },

}
